import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMedium, 
  faDiscord, 
  faLinkedin, 
  faFacebook, 
  faTwitter, 
  faInstagram, 
  faTiktok 
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const logoPath = '/images/vairee-logo.png';
  const socialLinks = [
    { icon: faMedium, link: 'https://medium.com/@vairee' },
    { icon: faDiscord, link: 'https://discord.com/invite/TFpjCjdVJq' },
    { icon: faLinkedin, link: 'https://www.linkedin.com/company/vairee' },
    { icon: faFacebook, link: 'https://www.facebook.com/vairee/' },
    { icon: faTwitter, link: 'https://x.com/_vairee' },
    { icon: faInstagram, link: 'https://www.instagram.com/vairee.io/' },
    { icon: faTiktok, link: 'https://www.tiktok.com/@vairee' },
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <span className="footer-text">powered by</span>
        <a href="https://www.vairee.ai" className="footer-link" target="_blank" rel="noopener noreferrer">
          <img src={logoPath} alt="vairee logo" className="footer-logo" />
        </a>
      </div>
      <p className="footer-text">
        ©2024 | all rights reserved | created by 
        <a href="https://www.linkedin.com/in/barushbalazikova/" className="footer-link" target="_blank" rel="noopener noreferrer">
           Barush Jensik
        </a>
      </p>

      <div className="decorative-line"></div>
 
      <div className="social-icons">
        {socialLinks.map((social, index) => (
          <a 
            key={index} 
            href={social.link} 
            className="social-icon" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={social.icon} />
          </a>
        ))}
      </div>
    </footer>
  );
};

export default Footer;