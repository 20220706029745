import React from 'react';

function AppCard({ name, description, category, link, onClick }) {
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank');
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <div className="input-group app-card">
      <h2>{name}</h2>
      <p>{description}</p>
      <p className="category">{category}</p>
      <button onClick={handleClick} className="app-button">
        Access App
      </button>
    </div>
  );
}

export default AppCard;