import React from 'react';

function Header() {
  return (
    <header>
      <h1>Little Career <span className="orange">AI</span> Apps</h1>
    </header>
  );
}

export default Header;