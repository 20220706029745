import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import Header from './components/Header';
import AppGrid from './components/AppGrid';
import FeedbackSection from './components/FeedbackSection';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <AppGrid />
      <FeedbackSection />
      <Footer />
      <Analytics />
    </div>
  );
}

export default App;