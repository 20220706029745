import React, { useState, useCallback } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

function FeedbackSection() {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);

  const API_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setErrorMessage('Please complete the CAPTCHA');
      return;
    }
    setIsSubmitting(true);
    setSubmitStatus(null);
    setErrorMessage('');

    try {
      const response = await fetch(`${API_URL}/api/send-feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback, captchaValue }),
      });
      
      console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      console.log('Response data:', responseData);

      setSubmitStatus('success');
      setFeedback('');
      setCaptchaValue(null);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSubmitStatus('error');
      setErrorMessage(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCaptchaChange = useCallback((value) => {
    setCaptchaValue(value);
  }, []);

  return (
    <section className="input-section">
      <div className="input-group">
        <h2>Let us help you <span className='orange'>improve</span> your <span className='orange'>Job Search</span> Experience</h2>
        <p>Our mission is to empower job seekers to find their dream jobs without the hefty fees of recruitment online services such as resume creation, interview prep etc. — because we believe support should be accessible to everyone.</p>
        <form onSubmit={handleSubmit}>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Your feedback matters! Share your ideas on how we can enhance the job-seeking experience."
            required
          ></textarea>
          <ReCAPTCHA
            sitekey="6Lcs5lAqAAAAAAH0UytKGYp_zP8bIg495bkdcJqv"
            onChange={handleCaptchaChange}
          />
          <button type="submit" disabled={isSubmitting || !captchaValue}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        {submitStatus === 'success' && <p className="success-message">Thank you for your feedback!</p>}
        {submitStatus === 'error' && <p className="error-message">{errorMessage}</p>}
      </div>
    </section>
  );
}

export default FeedbackSection;