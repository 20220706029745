import React from 'react';
import AppCard from './AppCard';

const apps = [
  {
    name: "Roast ME",
    description: "Get a fun. honest and friendly roast of your resume or profile.",
    category: "For Fun",
    link: "https://roast-me.vairee.ai",
    action: (link) => window.open(link, '_blank')
  },
  {
    name: "Check Me",
    description: "Get your application materials reviewed like a recruiter would.",
    category: "For Applicants and Recruiters",
    link: "https://resume-check.vairee.ai",
    action: () => console.log("Check Me clicked")
  },
  {
    name: "Pre-screen Me",
    description: "AI-powered pre-screening for job applications.",
    category: "For Applicants and Recruiters",
    link: "https://prescreen.vairee.ai",
    action: () => console.log("Pre-screen Me clicked")
  }
];

function AppGrid() {
  return (
    <div className="app-grid">
      {apps.map((app, index) => (
        <AppCard 
          key={index} 
          name={app.name} 
          description={app.description} 
          category={app.category} 
          link={app.link}
          onClick={app.action}
        />
      ))}
    </div>
  );
}

export default AppGrid;